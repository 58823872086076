/* CONTENIDO */
.contenido{background-color:var(--blue3);color:#fff;}
.contenido .content{display:flex;flex-wrap:wrap;}
.contenido__el__t1{display:flex;}
.contenido__el__t1 img{width:2rem;height:auto;margin-left:1rem;}
.contenido__el__t2{margin-bottom:1rem;}
.contenido__el__t2:last-child{margin-bottom:0;}

/* EXHIBICIONES ELEMENTOS */
.exhibiciones{display:flex;flex-wrap:wrap;}
.exhibiciones_con{z-index:2;background-color:var(--yellow);}
.exhibiciones_con__el{position:relative;overflow:hidden;}
.exhibiciones_con__el,.exhibiciones_con__el img{width:100%;height:auto;}
.exhibiciones_con__el_btn{position:absolute;top:0;left:0;width:100%;height:100%;z-index:10;}
.exhibiciones_con__el img{transition:all .5s ease;}
.exhibiciones_con__el:hover img{transform:scale(1.1);}
.exhibiciones_con__btn{background-color:var(--yellow);color:#fff;display:flex;justify-content:flex-end;align-items:center;text-decoration:none;}
.exhibiciones_con__btn img{width:2rem;height:auto;margin-right:1rem;transform:rotate(180deg);}
.exhibiciones_con__el_title{position:absolute;color:#fff;display:flex;align-items:center;justify-content:center;z-index:9;}
.exhibiciones_con__el_title img{width:3rem;height:auto;border-left:2px solid #fff;padding-left:1rem;margin-left:1rem;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .header_title{width:100%;padding:1.5rem 1rem;text-align:left;}
    .header_title__t1{font-size:3rem;line-height:2.5rem;}

    .contenido__el{width:100%;}
    .contenido__el__t1{font-size:2.2rem;line-height:2.2rem;}
    .contenido__el__t1:nth-child(2){margin-bottom:1rem;}
    .contenido__el__t2{font-size:1.1rem;}

    .exhibiciones{flex-direction:column-reverse;}
    .exhibiciones_con{width:100%;}
    .exhibiciones_con__btn{height:6rem;width:100%;padding-left:2rem;font-size:1.5rem;justify-content:flex-start;}
    .exhibiciones_con__el_title{bottom:1rem;left:1rem;font-size:1.5rem;}
    .exhibiciones_con__el_title img{width:2.5rem;padding-left:.8rem;margin-left:.8rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .header_title{padding:1.5rem 2rem;}
    .header_title__t1{font-size:3rem;line-height:2.5rem;}

    .contenido__el{width:100%;}
    .contenido__el__t1{font-size:2.2rem;line-height:2.2rem;}
    .contenido__el__t1:nth-child(2){margin-bottom:1rem;}
    .contenido__el__t2{font-size:1.1rem;}

    .exhibiciones{flex-direction:column-reverse;}
    .exhibiciones_con{width:100%;}
    .exhibiciones_con__btn{height:6rem;width:100%;padding-left:2rem;font-size:1.5rem;justify-content:flex-start;}
    .exhibiciones_con__el_title{bottom:2rem;right:2rem;font-size:1.5rem;}
    .exhibiciones_con__el_title img{width:2.5rem;padding-left:.8rem;margin-left:.8rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .header_title{padding:1.5rem 2rem;}
    .header_title__t1{font-size:3.5rem;line-height:3rem;}

    .contenido__el{width:50%;}
    .contenido__el__t1{font-size:2.2rem;line-height:2.2rem;max-width:21rem;margin-left:auto;padding-right:1.5rem;}
    .contenido__el__t2{font-size:1.1rem;padding-left:1.5rem;}

    .exhibiciones_con{width:50%;}
    .exhibiciones_con:nth-child(1){margin-top:-6rem;}
    .exhibiciones_con__btn{height:6rem;width:100%;}
    .exhibiciones_con__btn{padding-right:2rem;font-size:1.5rem;}
    .exhibiciones_con__el_title{bottom:2rem;right:2rem;font-size:1.5rem;}
    .exhibiciones_con__el_title img{width:2.5rem;padding-left:.8rem;margin-left:.8rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .header_title{padding:1.5rem 2.5rem;}
    .header_title__t1{font-size:4rem;line-height:3.5rem;}

    .contenido__el{width:50%;}
    .contenido__el__t1{font-size:2.5rem;line-height:2.5rem;max-width:24rem;margin-left:auto;padding-right:2rem;}
    .contenido__el__t2{font-size:1.2rem;padding-left:2rem;}

    .exhibiciones_con{width:50%;}
    .exhibiciones_con:nth-child(1){margin-top:-6rem;}
    .exhibiciones_con__btn{height:6rem;width:100%;}
    .exhibiciones_con__btn{padding-right:2rem;font-size:1.5rem;}
    .exhibiciones_con__el_title{bottom:2rem;right:2rem;font-size:1.8rem;}
}

@media (min-width:1400px){
    .header_title{padding:1.5rem 2.5rem;}
    .header_title__t1{font-size:5rem;line-height:4.5rem;}

    .contenido__el{width:50%;}
    .contenido__el__t1{font-size:2.5rem;line-height:2.5rem;max-width:24rem;margin-left:auto;padding-right:2rem;}
    .contenido__el__t2{font-size:1.2rem;padding-left:2rem;}

    .exhibiciones_con{width:50%;}
    .exhibiciones_con:nth-child(1){margin-top:-6rem;}
    .exhibiciones_con__btn{height:6rem;width:100%;}
    .exhibiciones_con__btn{padding-right:2rem;font-size:1.5rem;}
    .exhibiciones_con__el_title{bottom:2rem;right:2rem;font-size:2rem;}
}